import React from 'react'
import MainLayout from '../layouts/MainLayout'
import { COMPANY_NAME } from '../constants'
import CookiePreferences from '../components/CookiePreferences/CookiePreferences'

const Home: React.FunctionComponent = () => (
  <MainLayout title={`${COMPANY_NAME} - Cookie Preferences`}>
    <CookiePreferences />
  </MainLayout>
)

export default Home
