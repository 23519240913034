import React from 'react'
import './CookiePreferences.scss'
import Heading, { HeadingLevel, HeadingStyle } from '../Heading/Heading'

export default class CookiePreferences extends React.Component {
  private containerRef: React.RefObject<HTMLDivElement> = React.createRef()

  public componentDidMount() {
    if (this.containerRef.current) {
      const script = document.createElement('script')

      script.src = 'https://consent.cookiebot.com/91bf4523-00b7-45fe-af61-db0ecbc62661/cd.js'
      script.type = 'application/javascript'
      script.id = 'CookieDeclaration'
      script.async = true

      this.containerRef.current.appendChild(script)
    }
  }

  public render() {
    return (
      <div className="cookie-preferences__page">
        <Heading headingLevel={HeadingLevel.H1} headingStyle={HeadingStyle.TITLE}>
          Cookie Preferences
        </Heading>
        <div id="CookiebotPreferencesContainer" ref={this.containerRef} className="cookie-preferences__container" />
      </div>
    )
  }
}
