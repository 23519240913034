import React from 'react'
import classNames from 'classnames'

import './Heading.scss'

export enum HeadingLevel {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6
}

const elementByLevel: { [index: string]: string } = {
  [HeadingLevel.H1]: 'h1',
  [HeadingLevel.H2]: 'h2',
  [HeadingLevel.H3]: 'h3',
  [HeadingLevel.H4]: 'h4',
  [HeadingLevel.H5]: 'h5',
  [HeadingLevel.H6]: 'h6'
}

export enum HeadingStyle {
  HEADLINE,
  TITLE,
  SUBTITLE,
  PARAGRAPH,
  CUSTOM
}

interface Props {
  headingLevel: HeadingLevel
  headingStyle: HeadingStyle
  className?: any
  children: any
  id?: any
}

const Heading: React.SFC<Props> = ({ headingLevel, headingStyle, className, children, id }) => {
  const ElementType = elementByLevel[headingLevel] as any
  return (
    <ElementType
      className={classNames('h', {
        [className]: !!className,
        'h--headline': headingStyle === HeadingStyle.HEADLINE,
        'h--title': headingStyle === HeadingStyle.TITLE,
        'h--subtitle': headingStyle === HeadingStyle.SUBTITLE,
        'h--paragraph': headingStyle === HeadingStyle.PARAGRAPH
      })}
      id={id}
    >
      {children}
    </ElementType>
  )
}

export default Heading
